import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { LoadingController, ToastController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { StorageService } from '../services/storage.service';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { environment } from 'src/environments/environment';

// const apiV1 = 'https://cryptocougs.betaplanets.com/api/';
const apiV1 = 'https://backend.cryptocougs.com/api/';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    loading: any;


    constructor(
        public navCtrl: NavController,
        private http: HttpClient,
        public loadingCtrl: LoadingController,
        public storage: StorageService,
        private toastCtrl: ToastController,

    ) {
        if (!firebase.apps.length) {
            firebase.initializeApp(environment.firebaseConfig);
        } else {
            firebase.app(); // if already initialized, use that one
        }
    }

    // async dismissLoading() {
    //   console.log(this.loading);
    //   await this.loading.dismiss();
    // }

    // async showLoader(msg: string = '') {
    //   if (msg === '') {
    //     msg = 'Please wait...';
    //   }
    //   this.loading = await this.loadingCtrl.create({ message: msg });
    //   await this.loading.present();
    // }

    async successToast(msg) {
        const toast = await this.toastCtrl.create({
            message: msg,
            color: 'success',
            duration: 3000,
            position: 'bottom'
        });
        toast.present();
    }

    async errorToast(msg) {
        const toast = await this.toastCtrl.create({
            message: msg,
            color: 'danger',
            duration: 3000,
            position: 'bottom'
        });
        toast.present();
    }

    getData(endPoint) {
        return this.http.get(apiV1 + endPoint).pipe(
            map(data => data)
        );
    }

    sendData(endPoint, data) {
        // let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // data.timezone = timezone;
        return this.http.post(apiV1 + endPoint, data).pipe(
            map(result => result)
        );
    }

    doLogin(endPoint, data) {
        return this.http.post(apiV1 + endPoint, data).pipe(
            map(result => result)
        );
    }

    setToken(token) {
        window.localStorage.setItem("token", token);
    }

    getToken() {
        return JSON.parse(window.localStorage.getItem("token"));
    }


    doReset(email) {
        return this.http.post(apiV1 + 'forgot-password', {
            email: email
        }).pipe(
            map(user => {
                return user;
            })
        )
    }

    notificationSeen(data) {
        return this.http.post(apiV1 + 'seenNotification', data);
    }

    // ---firebase --

    firebaseAuthToken() {
        let email = 'cryptouser@knoxweb.com';
        let password = 'sR123456';
        firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {

            console.log('firebase login = ', { user });

        }).catch((err: any = []) => {
            console.log('err = ', { err });
            if (err.code == 'auth/user-not-found') {
                firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
                    console.log('firebase account created = ', { user });
                    this.firebaseAuthToken();
                });
            }

        });
    }

    firebaseSignOut() {
        firebase.auth().signOut();
    }



    // Stripe apis
    getStripeSettings() {
        return this.http.post(apiV1 + 'get_stripe_settings', {});
    }

    getCards(token: any) {
        return this.http.post(apiV1 + 'get_stripe_cards', {
            token: token,
        });
    }

    createStripeUser(token: any, stripeToken: any) {
        return this.http.post(apiV1 + 'create-stripe-user', {
            token: token,
            stripeToken: stripeToken
        });
    }

    update_card(token: any, card: any, card_new: any) {
        return this.http.post(apiV1 + "update_card", {
            token: token,
            card: card,
            name: card_new.name,
            expMonth: card_new.expMonth,
            expYear: card_new.expYear
        }).pipe(
            retry(2),
            map(content => {
                return content;
            })
        )
    }

    delete_card(token: any, card: any) {
        return this.http
            .post(apiV1 + 'delete_card', {
                token: token,
                card: card
            }).pipe(
                retry(2),
                map((content) => {
                    return content;
                })
            );
    }
    // End Stripe apis
}

